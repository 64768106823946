<template>
  <div>
    <HeaderComponent />
    <LoginComponent />
    <FooterComponent />
  </div>
</template>

<script>
import HeaderComponent from '../components/header/index.vue'
import FooterComponent from '../components/footer/index.vue'
import LoginComponent from '../components/login/index.vue'
export default {
  name: 'CheckoutPage',
  components: { HeaderComponent, FooterComponent, LoginComponent }
}
</script>
