<template>
    <div class="login_container p-t-150">
      <el-image
        class="login_logo"
        style="width: 100px; height: 100px"
        :src="logo"
        fit="cover"
      />
      <div v-if="is_login" class="login_container">
        <h2 class="login_title">
          НЭВТРЭХ
        </h2>
        <el-form ref="loginForm" :model="loginForm" :rules="rules" class="m-t-40 form_zone">
          <el-form-item prop="registration_number">
            <el-input v-model="loginForm.registration_number" placeholder="Нэвтрэх дугаар" clearable/>
          </el-form-item>
          <el-form-item class="m-t-20">
            <el-button  class="login_button p-12 cursor_pointer" @click="clickLogin('loginForm')">
              НЭВТРЭХ
            </el-button>
          </el-form-item>
                    <el-form-item style="margin-top: -15px;">
                   <el-button class="register_button p-12 cursor_pointer p-l-2" @click="clickRegister()">
              БҮРТГҮҮЛЭХ
            </el-button>
            </el-form-item>
          <a class="cursor_pointer">Нэвтрэх нэр мартсан уу?</a>
        </el-form>
      </div>
      <div v-if="is_register">
        <h2 class="login_title">
          БҮРТГҮҮЛЭХ
        </h2>
        <el-form ref="ruleForm" :model="registerForm" :rules="rulesRegister" class="form_zone m-t-40">
          <el-form-item prop="name">
            <el-input v-model="registerForm.phone" placeholder="Утасны дугаар" clearable />
          </el-form-item>
          <el-form-item prop="name">
            <button class="login_button p-12 cursor_pointer" @click="registerFunction()">
              БҮРТГҮҮЛЭХD
            </button>
            <button class="register_button p-12 m-t-10 cursor_pointer">
              БУЦАХ
            </button>
          </el-form-item>
        </el-form>
      </div>
      <div v-if="is_forget">
        <h2 class="login_title">
          БҮРТГЭЛ СЭРГЭЭХ
        </h2>
        <el-form ref="ruleForm" :model="loginForm" :rules="rules" class="form_zone m-t-40 p-20">
          <el-form-item prop="name">
            <el-input v-model="loginForm.phone" placeholder="Утасны дугаар" clearable />
          </el-form-item>
          <el-form-item prop="name">
            <button class="login_button p-12 cursor_pointer">
              СЭРГЭЭХ
            </button>
            <button class="register_button p-12 m-t-10 cursor_pointer">
              НЭВТРЭХ
            </button>
          </el-form-item>
        </el-form>
      </div>
    </div>
</template>
<script>
import logoImg from '../../assets/drysheep.png'
import axios from 'axios'
export default {
  name: 'DetailDialog',
  data () {
    return {
      is_login: true,
      is_register: false,
      is_forget: false,
      loginForm: {
        registration_number: null,
        phone: null
      },
      registerForm: {
        phone: null
      },
      dialog: false,
      count: 1,
      logo: logoImg,
      rules: {
        registration_number: [
          { required: true, message: 'Та бүртгэлийн дугаараа оруулна уу.', trigger: 'blur' },
          { min: 8, max: 8, message: 'Бүртгэлийн дугаар буруу байна', trigger: 'blur' }
        ]
      },
      rulesRegister: {
        phone: [
          { required: true, message: 'Та утасны дугаараа оруулна уу.', trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
  },
  methods: {
    registerFunction () {
      const data = JSON.stringify({
        phone: this.registerForm.phone
      })
      const config = {
        method: 'post',
        url: 'http://localhost:8000/api/registerApi',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      }
      axios.request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data))
          alert('ss')
        })
        .catch((error) => {
          console.log(error)
          alert('error')
        })
    },
    clickRegister () {
      this.is_login = false
      this.is_register = true
    },
    handleClose () {
      // this.$root.loginDialog = false
    },
    clickLogin (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // services.getProducts()
          //   .then((response) => {
          //     this.productList = response
          //     this.loading = false
          //   })
        } else {
          return false
        }
      })
    }
  }
}
</script>
