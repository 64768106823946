<template>
  <div class="basket">
    <div class="page-title-area p-t-150 p-b-100">
      <div class="container">
        <el-row class="row">
          <el-col :span="22" :offset="3">
            <div class="page-titel-detalis  ">
              <div class="section-title">
                <h2>Миний сагс</h2>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="wishlist-area shopping_cart shop-list p-t-105">
      <div class="container">
        <el-row>
          <el-col :xs="22" :sm="24" :md="12" :lg="12" :xl="12" :offset="detailWindowSize()">
            <div class="cart-table table-responsive">
              <el-row class="table table-bordered ">
                <el-col :xs="10" :sm="10" :md="4" :lg="8" :xl="8" scope="col">
                  <span>Бүтээгдэхүүн</span>
                </el-col>
                <el-col :xs="8" :sm="8" :md="4" :lg="6" :xl="6" scope="col">
                  <span>Тоо </span>
                </el-col>
                <el-col :xs="8" :sm="8" :md="4" :lg="4" :xl="4" scope="col" class="price_col">
                  <span>Үнэ</span>
                </el-col>
                <el-col :xs="8" :sm="8" :md="4" :lg="3" :xl="3" scope="col" class="price_col">
                  <span>Нийт үнэ</span>
                </el-col>
              </el-row>
              <el-row class="basket_item_row letter_spacing" v-for="(item,index) in basketItemsGetter" :key="index">
                <!-- ner -->
                <el-col :xs="10" :sm="12" :md="4" :lg="8" :xl="8" class="product-name">
                  <h6>{{ index + 1 }}. {{ item.product_name }}</h6>
                </el-col>
                <!-- too -->
                <el-col :xs="8" :sm="8" :md="6" :lg="6" :xl="6">
                  <div class="p-t-20">
                    <el-input-number class="visible_show_input_number" :min=1 v-model="item.item_count" buttonLayout="vertical" spinnerMode="vertical" size="mini"  @change="changeCount(item)"/>
                  </div>
                </el-col>
                <!-- negj une -->
                <el-col :span="4" class="price_col">
                  <div class="cart-button p-t-20">
                    <span v-if="item.id == 500">бэлэг</span>
                    <span v-else>{{(item.item_price) | formatCurrency}}</span>
                  </div>
                </el-col>
                <!-- niit une -->
                <el-col :span="4" class="price_col">
                  <div class="cart-button p-t-20">
                                        <span v-if="item.id == 500">бэлэг</span>
                    <span v-else>{{(item.item_price * item.item_count) | formatCurrency}}</span>
                  </div>
                </el-col>
                <!-- remove button -->
                <el-col :span="1">
                  <div class="cart-button p-t-20">
                    <button class="float-right" @click="removeCardItem(item)">
                      X
                    </button>
                  </div>
                </el-col>
              </el-row>
            </div>
            <!-- sags tsewerleh button -->
            <div class="table-button m-t-20 m-b-20">
              <button @click="clearBasket()">
                САГС ЦЭВЭРЛЭХ
              </button>
              <button class="m-l-35" @click="goToHome()">
                САГСАНД БАРАА НЭМЭХ
              </button>
            </div>
          </el-col>
          <el-col :xs="22" :sm="24" :md="7" :lg="7" :xl="7" :offset="1">
            <div class="container p-b-50">
              <div class="cart-total p-t-5 p-l-40 p-r-30 p-b-50 m-t-0 m-t-md-0">
                <h6>Нийт сагс</h6>
                <ul>
                  <li>Нийт бүтээгдэхүүн <span class="float-right">: {{ basketItemsGetterCount }}</span></li>
                  <li>Хүргэлт <span class="float-right">

                  <span>Үнэгүй</span>
                  <!-- <span v-if="totalAmountCalc() > 10000">{{this.deliveryPrice | formatCurrency}}</span> -->
                  </span></li>
                  <li>НИЙТ үнэ <span class="float-right">{{totalAmountCalc() | formatCurrency}}</span></li>
                </ul>
                <button class="p-btn border-0 m-t-20" @click="goToHome()">
                  ЗАХИАЛГА БАТАЛГААЖУУЛАХ
                </button>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import {
  mapGetters
} from 'vuex'
export default {
  name: 'BasketComponent',
  computed: {
    ...mapGetters([
      'basketItemsGetter',
      'basketItemsGetterCount'
    ])
  },
  data () {
    return {
      deliveryPrice: 0,
      valueNumber: 1
    }
  },
  created () {
    // this.addGift()
    // basketItemsGetter () {
    //   this.addGift()
    // }
  },
  methods: {
    addGift () {
      const giftItem = {
        id: 500,
        product_name: 'Бэлэг Нойтон салфетка',
        item_price: 0,
        item_count: 1,
        img_url: 'AoR zone LLC/products/2023-12-01144851.png',
        product_id: 54
      }
      if (this.totalAmountCalc() >= 67000) {
        const count = parseInt(this.totalAmountCalc() / 67000)
        console.log(count)
        giftItem.item_count = count
        this.$store.dispatch('add_basket_gift', giftItem)
      } else if (this.totalAmountCalc() < 67000) {
        this.$store.dispatch('remove_basket', giftItem)
      }
    },
    detailWindowSize () {
      console.log(window.innerWidth)
      if (window.innerWidth <= 760) {
        return 2
      } else {
        return 3
      }
    },
    goToHome () {
      this.$router.push({
        path: '/checkout'
      })
    },
    clearBasket () {
      this.$store.dispatch('clearBasket')
    },
    changeCount (item) {
      console.log(item)
      this.addGift()
      // this.$store.dispatch('add_basket', item)
    },
    removeCardItem (item) {
      this.$store.dispatch('remove_basket', item)
    },
    totalAmountCalc () {
      let sum = 0
      let total = 0
      this.basketItemsGetter.forEach(element => {
        sum = element.item_price * element.item_count
        total = sum + total
      })
      console.log(total)
      // if (total > 10000) {
      //   this.deliveryPrice = 0
      // } else {
      //   this.deliveryPrice = 5000
      // }
      return total + this.deliveryPrice
    }
  }
}
</script>
