import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const idToken = window.localStorage.getItem('idToken')
const products = window.localStorage.getItem('products')
const categories = window.localStorage.getItem('categories')
const selectCategory = window.localStorage.getItem('selectCategory')
const basketItems = window.localStorage.getItem('basket_items')
const lastOrder = window.localStorage.getItem('lastOrder')

export default new Vuex.Store({
  state: {
    idToken: idToken ? JSON.parse(idToken) : null,
    products: products ? JSON.parse(products) : null,
    categories: categories ? JSON.parse(categories) : [],
    selectCategory: selectCategory ? JSON.parse(selectCategory) : 'all',
    items: basketItems ? JSON.parse(basketItems) : [],
    lastOrder: lastOrder ? JSON.parse(lastOrder) : {}
  },
  getters: {
    idToken (state) {
      return state.idToken
    },
    productsGetter (state) {
      return state.products
    },
    categoriesGetter (state) {
      return state.categories
    },
    selectCategoryGetter (state) {
      return state.selectCategory
    },
    basketItemsGetterCount (state) {
      return state.items.length
    },
    basketItemsGetter (state) {
      return state.items
    },
    lastOrderGetter (state) {
      return state.lastOrder
    }
  },
  mutations: {
    removeFromCart (state, item) {
      const index = state.items.indexOf(item)
      state.items.splice(index, 1)
      window.localStorage.setItem('basket_items', JSON.stringify(state.items))
    },
    authUser (state, userData) {
      state.idToken = userData.token
      window.localStorage.setItem('idToken', JSON.stringify(state.idToken))
    },
    addProducts (state, products) {
      state.products = products
      window.localStorage.setItem('products', JSON.stringify(state.products))
    },
    addCategories (state, categoriesList) {
      state.categories = categoriesList
      window.localStorage.setItem('categories', JSON.stringify(state.categories))
    },
    clearBasket (state, category) {
      state.items = []
      window.localStorage.setItem('basket_items', JSON.stringify(state.items))
    },
    changeCategory (state, category) {
      console.log(category, 'ddaagii')
      state.selectCategory = category
      window.localStorage.setItem('selectCategory', JSON.stringify(state.selectCategory))
    },
    addLastOrder (state, lastOrderValue) {
      state.lastOrder = lastOrderValue
      window.localStorage.setItem('lastOrder', JSON.stringify(state.lastOrder))
    },
    addItems (state, value) {
      const found = state.items.find(item => item.id === value.id)
      if (found) {
        console.log('11')
        found.item_count = found.item_count + value.item_count
      } else {
        console.log('12', value)
        state.items.push(value)
      }
      window.localStorage.setItem('basket_items', JSON.stringify(state.items))
    },
    addItemsGift (state, value) {
      console.log('2')
      const found = state.items.find(item => item.id === value.id)
      if (found) {
        console.log(found, value, 'eervvv')
        found.item_count = value.item_count
      } else {
        state.items.push(value)
      }
      window.localStorage.setItem('basket_items', JSON.stringify(state.items))
    }
  },
  actions: {
    addLastOrder ({ commit }, value) {
      commit('addLastOrder', value)
    },
    clearBasket ({ commit }, value) {
      commit('clearBasket', value)
    },
    remove_basket ({ commit }, value) {
      console.log(value)
      commit('removeFromCart', value)
    },
    add_basket_gift ({ commit }, value) {
      commit('addItemsGift', value)
    },
    add_basket ({ commit }, value) {
      commit('addItems', value)
    },
    authUserLoginPage ({ commit }, response) {
      commit('authUser', response)
    },
    addProducts ({ commit }, response) {
      commit('addProducts', response)
    },
    addCategories ({ commit }, response) {
      commit('addCategories', response)
    },
    setCategory ({ commit }, value) {
      commit('changeCategory', value)
    }
  },
  modules: {
  }
})
