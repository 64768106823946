<template>
  <div class="slider p-t-80">
    <el-carousel indicator-position="outside" class="__slider_carousel">
      <el-carousel-item v-for="item in productsGetter" :key="item.id">
        <div>
          <el-row type="flex" justify="center" :span="24">
            <el-col :xs="24" :sm="10" :md="10" :lg="10" :xl="10" class="__slider_text_zone p-80">
              <div class="slider-description mt-200">
                <h1>{{ item.product_name }}<br> {{ item.count }}</h1>
                <p class="pb-30 pr-120">
                  {{ item.product_description }}
                </p>
                <a @click="add_product_to_basket(item)" class="slider-btn position-relative cursor_pointer">САГСАНД ХИЙХ</a>
              </div>
            </el-col>
            <el-col :xs="24" :sm="10" :md="10" :lg="10" :xl="10" class="__slider_img_zone">
              <div class="slider-images">
                <div class="slider-image-bg">
                  <img
                    :src="logoPage"
                    width="300px"
                    height="300px"
                    alt="shoes"
                  >
                  <span class="slider-price-badge">
                    <span>{{ item.price | formatCurrency}}</span>
                  </span>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>
<script>
import {
  mapGetters
} from 'vuex'
import logo from '../../assets/drysheep.png'
export default {
  name: 'SliderComponent',
  data () {
    return {
      logoPage: logo,
      slider_array: [
        {
          id: 1,
          title: 'L size',
          count: '36 pcs',
          description: '9-14кг жинтэй бүх хүүхдэд тохиромжтой.Илүү нимгэн илүү хурдан шингээлттэй хүүхдийг үргэлж хуурай байлгана.',
          img_url: 'https://fms-admin-images.s3.ap-southeast-1.amazonaws.com/AoR+zone+LLC/products/drysheep.png',
          price: '32,000'
        },
        {
          id: 2,
          title: 'S size',
          count: '44 pcs',
          description: '9-14кг жинтэй бүх хүүхдэд тохиромжтой.Илүү нимгэн илүү хурдан шингээлттэй хүүхдийг үргэлж хуурай байлгана.',
          img_url: 'https://fms-admin-images.s3.ap-southeast-1.amazonaws.com/AoR+zone+LLC/products/drysheep.png',
          price: '32,000'
        },
        {
          id: 3,
          title: 'M size',
          count: '36 pcs',
          description: '9-14кг жинтэй бүх хүүхдэд тохиромжтой.Илүү нимгэн илүү хурдан шингээлттэй хүүхдийг үргэлж хуурай байлгана.',
          img_url: 'https://fms-admin-images.s3.ap-southeast-1.amazonaws.com/AoR+zone+LLC/products/drysheep.png',
          price: '32,000'
        }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'productsGetter'
    ])
  },
  created () {

  },
  methods: {
    add_product_to_basket (item) {
      const basketItem = {
        id: item.branch_product_id,
        product_name: item.product_name,
        item_price: item.price,
        item_count: 1,
        img_url: item.img_url,
        product_id: item.product_id
      }
      this.$store.dispatch('add_basket', basketItem)
      this.$notify({
        title: 'Амжилттай',
        message: 'Таны сонгосон бүтээгдэхүүн сагсанд нэмэгдлээ',
        type: 'success'
      })
    }

  }
}

</script>
