<template>
    <div class="drysheep_loader" v-if="this.$root.fullScreenLoader">
        <div class="__visibility_block"></div>
        <div class="__loader_wrap">
            <div class="__loader_image"><span></span></div>
            <div class="__loader_content">Уншиж байна ...</div>
        </div>
    </div>
</template>
<script>
export default {
}
</script>
