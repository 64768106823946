<template>
  <div class="product">
    <el-row :gutter="20" class="__product_view p-20">
      <div class="new-arrival">
        <div class="container">
          <div class="section-title text-center p-t-30">
            <h2>БҮХ БҮТЭЭГДЭХҮҮН</h2>
          </div>
          <div class="arrival-menu text-center pt-20">
            <button :class="'abtn '+ activeClass('all')" data-filter="*" @click="goToCategoryByProduct(null)">
              Бүгд
            </button>
            <button v-for="(category_item, index) in categoriesGetter" :key=" index" :class="'abtn '+ activeClass(category_item.category_name)" data-filter="*" @click="goToCategoryByProduct(category_item)">
              {{ category_item.category_name }}
            </button>
          </div>
          <div v-loading="loading" class="arrival-product p-t-45">
            <el-row v-if="productsGetter.length" class="row grid" :span="20">
              <el-col v-for="(product, index) in productsGetter" :key="index" :xs="24" :sm="10" :md="8" :lg="6" :xl="6" class="grid-item cat1 cat4">
                <div :class="'arrival-items '+returnCssClass(product)+ ' text-center m-b-55 cursor_pointer'" @click="goToProductDetail(product)">
                  <!-- Зураг -->
                  <div class="arrival-img">
                    <img :src="'https://dmafilemanagement.s3.ap-southeast-1.amazonaws.com/images/' + product.img_url" height="350px" width="270px" alt="bag" style="border-radius: 20px;">
                  </div>
                  <div class="arrival-details position-relative" @click="goToProductDetail(product)">
                    <h5><a >{{ product.product_name }}</a></h5>
                    <span class="product_description">{{ product.product_description }}</span>
                    <div class="price price1">
                      <span class="ml-10 ">{{ product.price | formatCurrency}}</span>
                    </div>
                    <!-- <div class="buy-info">
                      <el-button class="slider-btn add-btn float-left position-relative" @click="goToProductDetail(product)">
                        Дэлгэрэнгүй
                      </el-button>
                      <ul class="wishlist text-right">
                        <li> <a @click="goToProductDetail(product)"><i class="lar la-heart" /></a></li>
                        <li>
                          <button class=" popbtn">
                            <i class="fas fa-search-plus" />
                          </button>
                        </li>
                      </ul>
                    </div> -->
                  </div>
                </div>
              </el-col>
            </el-row>
            <el-row v-else class="empty_zone m-t-100 m-b-100" type="flex" justify="center">
              <el-col :span="6">
                <el-image style="width: 100px; height: 100px" :src="emptyIconPage" fit="fill" /><br>
                <span class="m-t-20">Уучлаарай энэ ангилал хоосон байна.<br>
                  Та өөр ангилал сонгоод үзэх үү</span>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </el-row>
    <DetailDialog :product="selectProductObj" />
  </div>
</template>
<script>
import emptyIcon from '../../assets/empty-box.png'
import DetailDialog from '../product/detail.vue'
import services from '@/helpers/services.js'
import {
  mapGetters
} from 'vuex'
export default {
  name: 'ProductComponent',
  components: {
    DetailDialog
  },
  data () {
    return {
      productList: [],
      categories: [],
      loading: true,
      emptyIconPage: emptyIcon,
      activeTab: 'all',
      showDetail: false,
      selectProductObj: {
        name: 'dsad',
        item_count: 1
      }
    }
  },
  computed: {
    ...mapGetters([
      'productsGetter',
      'selectCategoryGetter',
      'categoriesGetter'
    ])
  },
  created () {
    this.getCategories()
    this.checkProduct()
  },
  watch: {
    activeTab () {
      this.checkProduct()
    }
  },
  methods: {
    checkProduct () {
      const localProducts = window.localStorage.getItem('products')
      if (!localProducts) {
        this.getProductList(null)
        this.productList = JSON.parse(localProducts)
      } else {
        this.productList = JSON.parse(localProducts)
        this.loading = false
      }
    },
    activeClass (data) {
      if (this.selectCategoryGetter === data) {
        return 'active'
      }
    },
    goToProductDetail (data) {
      this.$root.detailDialog = true
      this.selectProductObj = data
      // alert('dasds')
    },
    goToCategoryByProduct (category) {
      this.loading = true
      if (category == null) {
        this.getProductList(null)
        this.$store.dispatch('setCategory', 'all')
      } else {
        this.$store.dispatch('setCategory', category.category_name)
        this.getProductList(category.id)
      }
    },
    returnCssClass (data) {
      if (data.category_name === 'Сальфетка') {
        return 'arrival-paper-items'
      } else if (data.category_name === 'Живх') {
        return 'arrival-daipers-items'
      } else {
        return 'arrival-pack-items'
      }
    },
    getProductList (categoryId) {
      const payload = {}
      if (categoryId !== null) {
        payload.branch_id = 53
        payload.category_id = categoryId
      } else {
        payload.branch_id = 53
      }
      services.getProducts(payload)
        .then((response) => {
          // this.productList = response
          response.forEach(element => {
            element.item_count = 1
          })
          this.$store.dispatch('addProducts', response)

          this.loading = false
        })
    },
    getCategories () {
      // const posNumber = '70663'
      const localCategories = window.localStorage.getItem('categories')
      if (!localCategories) {
        const payload = {
          company_id: '9',
          category_id: null

        }
        services.getCategories(payload)
          .then((response) => {
            if (response.status === 'success') {
            // this.categories = response.result
              this.$store.dispatch('addCategories', response.result)
            }
          })
      } else {
        this.categories = JSON.parse(localCategories)
      }
    }
  }
}

</script>
