<template>
  <div class="basket">
    <div class="page-title-area p-t-150 p-b-100">
      <div class="container">
        <el-row class="row">
          <el-col :span="22" :offset="3">
            <div class="page-titel-detalis  ">
              <div class="section-title">
                <h2>Баталгаажуулах</h2>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="wishlist-area shopping_cart shop-list p-b-40">
      <div class="container">
        <el-row>
          <el-col :xs="22" :sm="20" :md="9" :lg="8" :xl="9" :offset="detailWindowSize()">
            <div class="checkout-area">
              <div class="container">
                <!-- <el-row> -->
                    <!-- <div class="checkout">
                      <p>Returning customer ?<a href="checkout_page.html"> Click here to login</a></p>
                      <p>Have a Coupon ? <a href="my_wishlist.html">Click here to enter your code</a></p>
                    </div> -->
                      <div class="bill-details">
                        <h6>Захиалгын дэлгэрэнгүй</h6>
                        <el-form  class="bill-form" :model="order" :rules="rules" ref="orderForm" >
                          <el-row>
                                  <el-form-item :span="20" class="country" prop="address_city">
                                <el-col :xs="20" :sm="20" :md="24" :lg="24" :xl="24">
                                  <label for="country">Хот аймаг</label>
                                </el-col>
                                <el-col :xs="20" :sm="20" :md="24" :lg="24" :xl="24">
                                  <div class="country-select position-relative m-t-10">
                                    <el-select class="w-100" id="country" name="countries" v-model="order.address_city" placeholder="Хот аймаг сонгох" clearable>
                                        <el-option
                                          v-for="item in cities"
                                          :key="item.value"
                                          :label="item.label"
                                          :value="item.value">
                                        </el-option>
                                    </el-select>
                                  </div>
                                </el-col>
                              </el-form-item>
                              <el-form-item :span="20" class="country" prop="address_district">
                                <el-col :xs="20" :sm="20" :md="24" :lg="24" :xl="24">
                                  <label for="country">Дүүрэг</label>
                                </el-col>
                                <el-col :xs="20" :sm="20" :md="24" :lg="24" :xl="24">
                                  <div class="country-select position-relative m-t-10">
                                    <el-select class="w-100" id="country" name="countries" v-model="order.address_district" placeholder="Дүүрэг сонгох" clearable>
                                        <el-option
                                          v-for="item in districts"
                                          :key="item.value"
                                          :label="item.label"
                                          :value="item.value">
                                        </el-option>
                                    </el-select>
                                  </div>
                                </el-col>
                              </el-form-item>
                            <el-form-item class="country" prop="address_khoroo">
                              <el-col :xs="20" :sm="20" :md="24" :lg="24" :xl="24">
                                <label for="country">Хороо</label>
                              </el-col>
                              <el-col :xs="20" :sm="20" :md="24" :lg="24" :xl="24">
                                <div class="country-select position-relative m-t-10">
                                  <el-select class="w-100" id="country" name="countries" v-model="order.address_khoroo" placeholder="Хороо сонгох" clearable>
                                      <el-option
                                        v-for="item in khoroos"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                      </el-option>
                                  </el-select>
                                </div>
                              </el-col>
                            </el-form-item>
                                 <el-form-item class="country" prop="address_bair">
                              <el-col :xs="20" :sm="20" :md="24" :lg="24" :xl="24">
                                <label for="country">Байрны тоот /Хашааны дугаар</label>
                              </el-col>
                              <el-col :xs="20" :sm="20" :md="24" :lg="24" :xl="24">
                                <div class="country-select position-relative m-t-10">
                                    <el-input id="addres" class="m-t-10" type="text" placeholder="1 байр /123 тоот" v-model="order.address_bair"></el-input>
                                </div>
                              </el-col>
                            </el-form-item>
                            <el-form-item class="address" prop="address_description">
                              <el-col :xs="20" :sm="20" :md="24" :lg="24" :xl="24">
                                <label for="addres">Хаягийн дэлгэрэнгүй</label>
                              </el-col>
                              <el-col :xs="20" :sm="20" :md="24" :lg="24" :xl="24">
                                <el-input id="addres" class="m-t-10" type="text" placeholder="Бид таныг олоход туслаарай. /Мухар гудамж, Ганцхан байр, Хаалттай шүү гэх мэт/" v-model="order.address_description"></el-input>
                              </el-col>
                            </el-form-item>
                                <!-- Utas -->
                            <el-form-item class="phone" prop="customer_phone">
                              <el-col :xs="20" :sm="20" :md="24" :lg="24" :xl="24">
                                <label for="pho">Утас</label>
                              </el-col>
                              <el-col :xs="20" :sm="20" :md="24" :lg="24" :xl="24">
                                <el-input id="pho" class="m-t-10 w-100" v-model="order.customer_phone" type="number" placeholder="Үндсэн утас"></el-input>
                              </el-col>
                            </el-form-item>
                                 <!-- <el-form-item class="phone" prop="promo_code">
                              <el-col :xs="20" :sm="20" :md="24" :lg="24" :xl="24">
                                <label for="pho">Промо код</label>
                              </el-col>
                              <el-col :xs="20" :sm="20" :md="24" :lg="24" :xl="24">
                                <el-input class="m-t-10 w-100" v-model="order.promo_code" placeholder="Промо код /заавал бөглөх шаардлагагүй/"></el-input>
                              </el-col>
                            </el-form-item> -->
                                    <!-- <div class="row p-t-30">
                                  <div class="col-xl-6 col-lg-6 col-sm-6"> -->
                                    <!-- Nemelt utas -->
                            <!-- <el-form-item class="phone">
                              <el-col :xs="20" :sm="20" :md="24" :lg="24" :xl="24">
                                <label for="pho">Нэмэлт утас</label>
                              </el-col>
                              <el-col :xs="20" :sm="20" :md="24" :lg="24" :xl="24">
                                <el-input id="pho" class="m-t-10 w-100" v-model="order.phone2" type="number" placeholder="Нэмэлт утас"></el-input>
                              </el-col>
                            </el-form-item> -->
                                <!-- </div>
                              </div> -->
                              <!-- zahialgiin temdeglel -->
                            <el-form-item class="order-note p-t-30">
                              <el-col :xs="20" :sm="20" :md="24" :lg="24" :xl="24">
                                <label for="note">Захиалгын тэмдэглэл</label>
                              </el-col>
                              <el-col :xs="20" :sm="20" :md="24" :lg="24" :xl="24">
                                <el-input type="textarea" id="note" v-model="order.order_description" class="m-t-10" cols="30" rows="5" placeholder="Ирээд залгаарай, Эргэлт *** эгчээс нь ахаас нь гэх мэт :), төхөөд хэлээрэй гараад авна." />
                              </el-col>
                            </el-form-item>
                            <!-- </div> -->
                          </el-row>
                        </el-form>
                      </div>
                <!-- </el-row> -->
              </div>
            </div>
          </el-col>
          <el-col :xs="20" :sm="18" :md="9" :lg="9" :xl="9" :offset="2">
            <div class="product-order p-l-50 p-r-50 p-t-30 p-b-55 m-t-50 m-b-50">
              <el-row>
                <el-col>
                  <h6 class="text-center pb-10">
                  Таны захиалга
                </h6>
                </el-col>
                <span>Бүтээгдэхүүн</span>
                <ul v-for="(item, index) in this.basketItemsGetter" :key="index">
                  <li>{{ item.product_name }} <span class="float-right"> - <b>{{ item.item_count }} ш</b> -<b>{{ item.item_price * item.item_count | formatCurrency }}</b></span></li>
                </ul>
                <hr class="mt-5">
                <p class="pt-15">
                  Бүтээгдэхүүний нийт төлбөр <span class="float-right">{{ totalAmountCalc() | formatCurrency }}</span>
                </p>
                <hr>
                <p class="pt-15">
                  Хүргэлт үнэгүй
                </p>
                <hr>
                <p class="price-total pt-15">
                  Нийт <span class="float-right"><b>{{totalAmountCalc() | formatCurrency}}</b></span>
                </p>
                <div class="payment-method mt-55">
                  <!-- <div class="p-t-30 p-b-30">
                    <el-checkbox v-model="checked">
                      <a href="https://www.drysheep.mn" target="_blank">  Үйлчилгээний нөхцөл зөвшөөрч байна.</a>
                    </el-checkbox>
                  </div> -->
                  <div class="payment-card position-relative pl-25 mt-15">
                    <input id="card" type="radio" name="payment">
                    <span class="check-mark" />
                    <label for="card">
                      <span><i class="fab fa-cc-mastercard" /></span>
                      <span><i class="fab fa-cc-visa" /></span>
                      <span><i class="fab fa-paypal" /></span>
                      <span><i class="fab fa-cc-discover" /></span>
                    </label>
                  </div>
                  <button class="p-btn border-0 mt-45" @click="saveOrder('orderForm')">
                    Захиалах
                  </button>
                </div>
              </el-row>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import {
  mapGetters
} from 'vuex'
import services from '@/helpers/services.js'
// import axios from 'axios'

export default {
  name: 'BasketComponent',
  computed: {
    ...mapGetters([
      'basketItemsGetter',
      'lastOrderGetter'
    ])
  },
  data () {
    return {
      deliveryPrice: 0,
      valueNumber: 1,
      checked: false,
      cities: [{
        value: 'Ulaanbaatar',
        label: 'Улаанбаатар'
      }, {
        value: 'Erdenet',
        label: 'Эрдэнэт'
      }, {
        value: 'Dornod',
        label: 'Дорнод'
      }],
      districts: [
        {
          value: 'БЗД',
          label: 'БЗД'
        },
        {
          value: 'ХУД',
          label: 'ХУД'
        },
        {
          value: 'БГД',
          label: 'БГД'
        },
        {
          value: 'СБД',
          label: 'СБД'
        },
        {
          value: 'СХД',
          label: 'СХД'
        },
        {
          value: 'ЧД',
          label: 'ЧД'
        },
        {
          value: 'НАЛАЙХ',
          label: 'НАЛАЙХ'
        },
        {
          value: 'НД',
          label: 'НД'
        }
      ],
      khoroos: [{
        value: '1 хороо',
        label: '1 хороо'
      },
      {
        value: '2 хороо',
        label: '2 хороо'
      },
      {
        value: '3 хороо',
        label: '3 хороо'
      }, {
        value: '4 хороо',
        label: '4 хороо'
      }, {
        value: '5 хороо',
        label: '5 хороо'
      }, {
        value: '6 хороо',
        label: '6 хороо'
      }, {
        value: '7 хороо',
        label: '7 хороо'
      }, {
        value: '8 хороо',
        label: '8 хороо'
      }, {
        value: '9 хороо',
        label: '9 хороо'
      }, {
        value: '10 хороо',
        label: '10 хороо'
      }, {
        value: '11 хороо',
        label: '11 хороо'
      }, {
        value: '12 хороо',
        label: '12 хороо'
      }, {
        value: '13 хороо',
        label: '13 хороо'
      }, {
        value: '14 хороо',
        label: '14 хороо'
      }, {
        value: '15 хороо',
        label: '15 хороо'
      }, {
        value: '16 хороо',
        label: '16 хороо'
      }, {
        value: '17 хороо',
        label: '17 хороо'
      }, {
        value: '18 хороо',
        label: '18 хороо'
      }, {
        value: '19 хороо',
        label: '19 хороо'
      }, {
        value: '20 хороо',
        label: '20 хороо'
      }, {
        value: '21 хороо',
        label: '21 хороо'
      }, {
        value: '22 хороо',
        label: '22 хороо'
      }, {
        value: '23 хороо',
        label: '23 хороо'
      }, {
        value: '24 хороо',
        label: '24 хороо'
      }, {
        value: '25 хороо',
        label: '25 хороо'
      }, {
        value: '26 хороо',
        label: '26 хороо'
      }, {
        value: '27 хороо',
        label: '27 хороо'
      }, {
        value: '28 хороо',
        label: '28 хороо'
      }, {
        value: '29 хороо',
        label: '29 хороо'
      }, {
        value: '30 хороо',
        label: '30 хороо'
      }, {
        value: '31 хороо',
        label: '31 хороо'
      }, {
        value: '32 хороо',
        label: '32 хороо'
      }, {
        value: '33 хороо',
        label: '33 хороо'
      }, {
        value: '34 хороо',
        label: '34 хороо'
      }, {
        value: '35 хороо',
        label: '35 хороо'
      }, {
        value: '36 хороо',
        label: '36 хороо'
      }, {
        value: '37 хороо',
        label: '37 хороо'
      }, {
        value: '38 хороо',
        label: '38 хороо'
      }, {
        value: '39 хороо',
        label: '39 хороо'
      }, {
        value: '40 хороо',
        label: '40 хороо'
      }, {
        value: '41 хороо',
        label: '41 хороо'
      }, {
        value: '42 хороо',
        label: '42 хороо'
      }, {
        value: '43 хороо',
        label: '43 хороо'
      }, {
        value: '44 хороо',
        label: '44 хороо'
      }, {
        value: '45 хороо',
        label: '45 хороо'
      }, {
        value: '46 хороо',
        label: '46 хороо'
      }, {
        value: '47 хороо',
        label: '47 хороо'
      }],
      order: {
        branch_name: 'Веб',
        company_name: 'AoR zone LLC',
        address_city: 'Улаанбаатар',
        address_district: null,
        address_id: null,
        address_khoroo: null,
        address_bair: null,
        address_description: '',
        order_type: '1',
        customer_phone: null,
        order_channel: 'web',
        pay_type: '1',
        vat_type: 1,
        vat_regno: null,
        vat_cust_name: '',
        order_description: '',
        order_status: 40,
        worker_key: null,
        pos_number: '',
        total_amount: 0,
        promo_code: '',
        products: []
      },
      payment: {
        cash: 0,
        credit_card: 0,
        coupon: 0,
        loyalty_point: 0,
        account: 0
      },
      rules: {
        address_city: [
          { required: true, message: 'Аймаг хот сонгоогүй байна', trigger: 'blur' }
        ],
        address_district: [
          { required: true, message: 'Дүүрэг сонгоогүй байна', trigger: 'blur' }
        ],
        address_khoroo: [
          { required: true, message: 'Хороо сонгоогүй байна', trigger: 'blur' }
        ],
        address_description: [
          { required: true, message: 'Хаягийн дэлгэрэнгүй', trigger: 'blur' }
        ],
        customer_phone: [
          { required: true, message: 'Үйлчлүүлэгчийн утасны дугаар', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    detailWindowSize () {
      if (window.innerWidth <= 760) {
        return 3
      } else {
        return 3
      }
    },
    saveOrder (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.order.total_amount = this.totalAmountCalc()
          const payload = {
            order: this.order,
            products: this.basketItemsGetter,
            payment: this.payment

          }
          // this.order.products = this.basketItemsGetter
          services.orderSave(payload)
            .then((response) => {
              this.$store.dispatch('addLastOrder', response)
              this.$store.dispatch('clearBasket')
              if (response) {
                this.$alert('Таны захиалга амжилттай бүртгэгдлээ', 'Амжилттай', {
                  confirmButtonText: 'Ойлголоо',
                  callback: action => {
                    const payload = {
                      to: this.lastOrderGetter.customer_phone,
                      text: 'Таны ' + this.lastOrderGetter.id + 'дугаартай захиалга амжилттай баталгаажлаа. Хаан банкны 5305979107 / Б. Мөнхнасан / тоот дансанд ' + this.lastOrderGetter.total_amount + 'төгрөгийг ' + this.lastOrderGetter.id + 'гүйлгээний утгатай гүйлгээ хийгдсэний дараа захиалга баталгаажна.Drysheep'
                    }
                    services.successOrderSendInfo(payload)
                      .then((response) => {
                        console.log(response, action)
                      })
                      .catch((err) => {
                        console.log(err)
                      })
                    this.$router.push({
                      path: '/payment_auth'
                    })
                  }
                })
              }
              // this.productList = response
              // this.$store.dispatch('addProducts', response)
              console.log(response)
              this.loading = false
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    totalAmountCalc () {
      let sum = 0
      let total = 0
      this.basketItemsGetter.forEach(element => {
        sum = element.item_price * element.item_count
        total = sum + total
      })
      // if (total > 10000) {
      //   this.deliveryPrice = 0
      // } else {
      //   this.deliveryPrice = 5000
      // }
      return total + this.deliveryPrice
    }
  }
}
</script>
