import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/scss/index.css'

Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.filter('formatCurrency', function (value) {
  return new Intl.NumberFormat().format(value) + '₮'
})
new Vue({
  router,
  store,
  data: {
    detailDialog: false,
    loginDialog: false,
    registerDialog: false
  },
  render: h => h(App)
}).$mount('#app')
