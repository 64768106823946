<template>
  <el-dialog
    :visible.sync="this.$root.detailDialog"
    :width="detailWindowSize()"
    :before-close="handleClose"
    class="product-popupList product-popup2"
  >
    <el-row class="product-popup shop-details p-b-60" :span="24">
      <el-col :xs="24" :sm="24" :md="10" :lg="10" :xl="10" :offset="1">
        <div class="product-img">
          <img :src="'https://dmafilemanagement.s3.ap-southeast-1.amazonaws.com/images/' + product.img_url" min-height="350px" width="270px" alt="bag">
        </div>
      </el-col>
      <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
        <el-row class="product-details ml-30 mt-70">
          <el-col><h5>{{ product.product_name }}</h5></el-col>
          <el-col>
            <div class="price pb-10">
              <span>{{ product.price }}  ₮</span>
            </div>
          </el-col>
          <el-col>
            <p class="pr-110">
              {{ product.product_description }}
            </p>
          </el-col>
          <el-col>
            <div class="product-count">
              <div class="action-row">
                <el-input-number v-model="count" :min="1" :max="10"  size="small"/>
                <el-button type="success" size="small" class="m-l-20" @click="add_product_to_basket()">
                  САГСАНД НЭМЭХ
                </el-button>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </el-dialog>
</template>
<script>
import {
  mapGetters
} from 'vuex'
export default {
  name: 'DetailDialog',
  computed: {
    ...mapGetters([
      'basketItemsGetter',
      'basketItemsGetterCount'
    ])
  },
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      dialog: false,
      count: 1,
      deliveryPrice: 0
    }
  },
  created () {
  },
  methods: {
    addGift () {
      const giftItem = {
        id: 500,
        product_name: 'Бэлэг Нойтон салфетка',
        item_price: 0,
        item_count: 1,
        img_url: 'AoR zone LLC/products/2023-12-01144851.png',
        product_id: 54
      }
      console.log(this.totalAmountCalc(), 'ssd')
      if (this.totalAmountCalc() >= 67000) {
        const count = parseInt(this.totalAmountCalc() / 67000)
        giftItem.item_count = count
        this.$store.dispatch('add_basket_gift', giftItem)
      } else if (this.totalAmountCalc() < 67001) {
        this.$store.dispatch('remove_basket', giftItem)
      }
    },
    detailWindowSize () {
      console.log(window.innerWidth)
      if (window.innerWidth <= 760) {
        return '350px'
      } else {
        return '900px'
      }
    },
    totalAmountCalc () {
      let sum = 0
      let total = 0
      this.basketItemsGetter.forEach(element => {
        sum = element.item_price * element.item_count
        total = sum + total
      })
      console.log(total)
      // if (total > 10000) {
      //   this.deliveryPrice = 0
      // } else {
      //   this.deliveryPrice = 5000
      // }
      return total + this.deliveryPrice
    },
    add_product_to_basket () {
      const basketItem = {
        id: this.product.branch_product_id,
        product_name: this.product.product_name,
        item_price: this.product.price,
        item_count: this.count,
        img_url: this.product.img_url,
        product_id: this.product.product_id
      }
      this.$store.dispatch('add_basket', basketItem)
      if (this.totalAmountCalc() >= 67000) {
        this.addGift()
      }
      this.$notify({
        title: 'Амжилттай',
        message: 'Таны сонгосон бүтээгдэхүүн сагсанд нэмэгдлээ',
        type: 'success',
        position: 'bottom-right'
      })
    },
    handleClose () {
      this.count = 1
      this.$root.detailDialog = false
    }
  }
}
</script>
