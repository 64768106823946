<template>
  <div>
    <HeaderComponent />
    <CheckOutComponent />
    <FooterComponent />
  </div>
</template>

<script>
import HeaderComponent from '../components/header/index.vue'
import FooterComponent from '../components/footer/index.vue'
import CheckOutComponent from '../components/checkout/index.vue'
export default {
  name: 'CheckoutPage',
  components: { HeaderComponent, FooterComponent, CheckOutComponent }
}
</script>
