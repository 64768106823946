import {
  mainRequests
} from './custom'
export default {
  getCategories (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get('/getCategories?category_id=' + payload.category_id + '&company_id=' + payload.company_id)
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getProducts (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post('/getActiveWebProducts', payload)
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  login (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postDrysheep('/login', payload)
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  registerService (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postDrysheep('/registerApi', payload)
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  orderSave (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post('/orderSave', payload)
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  successOrderSendInfo (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post('/successOrderSendInfo', payload)
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}
