import config from '@/config/index'
import { getAccessToken } from '@/helpers/auth.js'

export const mainRequests = {
  post,
  get,
  deleteFunction,
  getAuth,
  postAuth,
  postDrysheep
}

function handleResponse (response) {
  return response
    .text()
    .then((text) => {
      const data = text && JSON.parse(text)
      if (!response.ok) {
        if (response.status === 401) {
          //   logout()npm run serve
          // location.reload()
        }

        const error = (data && data.message) || response.statusText
        return Promise.reject(error)
      }

      return data
    })
    .catch((err) => {
      return Promise.reject(err)
    })
}
async function post (url, payload) {
  const promise = new Promise((resolve, reject) => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload)
    }
    fetch(`${config.BASE_SERVER_PATH}` + url, requestOptions)
      .then(handleResponse)
      .then((data) => {
        resolve(data)
      })
      .catch((error) => {
        reject(error)
      })
  })
  const result = await promise
  return result
}
async function get (url) {
  const promise = new Promise((resolve, reject) => {
    fetch(`${config.BASE_SERVER_PATH}` + url)
      .then(handleResponse)
      .then((data) => {
        resolve(data)
      })
      .catch((error) => {
        reject(error)
      })
  })
  const result = await promise
  return result
}

async function postAuth (url, payload = {}) {
  const promise = new Promise((resolve, reject) => {
    const token = getAccessToken()
    const requestOptions = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`
      },
      body: JSON.stringify(payload)
    }
    fetch(`${config.BASE_SERVER_PATH}` + url, requestOptions)
      .then(handleResponse)
      .then((data) => {
        resolve(data)
      })
      .catch((error) => {
        reject(error)
      })
  })
  const result = await promise
  return result
}
async function getAuth (url) {
  const promise = new Promise((resolve, reject) => {
    const token = getAccessToken()
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    }
    fetch(`${config.BASE_SERVER_PATH}` + url, requestOptions)
      .then(handleResponse)
      .then((data) => {
        if (data.message === 'empty_role') {
          window.location.href = '/authError'
        } else {
          resolve(data)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
  const result = await promise
  return result
}
async function deleteFunction (url, payload) {
  const promise = new Promise((resolve, reject) => {
    const requestOptions = {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload)
    }
    fetch(`${config.BASE_SERVER_PATH}` + url, requestOptions)
      .then(handleResponse)
      .then((data) => {
        resolve(data)
      })
      .catch((error) => {
        reject(error)
      })
  })
  const result = await promise
  return result
}
export function dateFormatFunction (date) {
  return date.split('T')[0] + ' ' + date.split('T')[1].substring(0, 8)
}
async function postDrysheep (url, payload) {
  const promise = new Promise((resolve, reject) => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload)
    }
    fetch(`${config.DRYSHEEP_SERVER_PATH}` + url, requestOptions)
      .then(handleResponse)
      .then((data) => {
        resolve(data)
      })
      .catch((error) => {
        reject(error)
      })
  })
  const result = await promise
  return result
}
