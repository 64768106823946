<template>
  <header>
    <div class="header-1 sticky">
      <div class="container">
        <el-row class="sticky header-1" type="flex">
          <el-col :xs="16" :sm="16" :md="4" :lg="4" :xl="4">
            <div class="logo text-md-center text-lg-left">
              <a href="/"><img class="m-t-40" src="@/assets/drysheep_logo.svg" alt="aruk-logo"></a>
            </div>
          </el-col>
          <el-col :xs="6" :sm="6" :md="12" :lg="12" :xl="12" class="menu_router_checker m-l-40">
            <div>
              <el-menu mode="horizontal" :default-active="activeIndex" active-text-color="#80c4c9">
                <template>
                  <router-link to="/">
                    <el-menu-item class="desktop_menu cursor-p" index="1">НҮҮР</el-menu-item>
                  </router-link>
                  <router-link to="/">
                    <el-menu-item @click="goToContact()" class="desktop_menu" index="3">ХОЛБОО БАРИХ</el-menu-item>
                  </router-link>
                </template>
              </el-menu>
            </div>
          </el-col>
          <el-col :xs="6" :sm="6" :md="4" :lg="4" :xl="4">
            <div class="nav-icons p-t-10">
              <ul >
                <li class="cursor_pointer">
                  <a href="/basket">
                    <el-badge :value="basketItemsGetterCount" class="item" type="warning">
                      <b>
                        <el-image style="width: 15px; height: 15px" :src="basketIcon" fit="fill"></el-image>
                      </b>
                    </el-badge>
                  </a>
                </li>
                    <li class="m-l-20 cursor_pointer">
                  <div @click="checkLogin()">
                      <b>
                        <el-image style="width: 15px; height: 15px" :src="AccountIconPage" fit="fill"></el-image>
                      </b>
                    </div>
                </li>
              </ul>
            </div>
          </el-col>
          <!-- <el-col :xs="2" :sm="2" :md="2" :lg="2" :xl="2">
       <el-menu :default-active="activeIndex" active-text-color="#80c4c9">
             <el-menu-item index="4" class="mobile_menu drawer_menu">
            <el-button type="text" @click="openDrawerMenu()"><i class="el-icon-menu"></i>
            </el-button>
          </el-menu-item>
          </el-menu>
    </el-col> -->
        </el-row>
        <div class="mobile-menu" />
      </div>
      <el-drawer title="Меню" :visible.sync="drawerMenuStatus" direction="rtl" size="300"
        :before-close="handleCloseDrawer">
        <div>
          <el-menu default-active="2">
            <el-menu-item index="2">
              <i class="el-icon-s-home"></i>
              <span @click="goToHome()">НҮҮР</span>
            </el-menu-item>
            <el-menu-item>
              <i class="el-icon-phone"></i>
              <span @click="goToContact()">ХОЛБОО БАРИХ</span>
            </el-menu-item>
          </el-menu>
        </div>
      </el-drawer>
    </div>
  </header>
</template>
<script>
import {
  mapGetters
} from 'vuex'
import LogoSvg from '../../assets/drysheep_logo.svg'
import iconPng from '../../assets/basket_icon.svg'
import AccountIcon from '../../assets/account_icons_black.svg'
export default {
  name: 'HeaderComponent',
  components: {},
  data () {
    return {
      logo: LogoSvg,
      drawerMenuStatus: false,
      activeIndex: '1',
      basketIcon: iconPng,
      AccountIconPage: AccountIcon
    }
  },
  computed: {
    ...mapGetters([
      'basketItemsGetterCount'
    ])
  },
  methods: {
    goToBasketPage () {
      this.$router.push({
        path: '/basket'
      })
    },
    goToHome () {
      this.$router.push({
        path: '/'
      })
    },
    goToContact () {
      this.$router.push({
        path: '/contact_page.html'
      })
    },
    checkLogin () {
      this.$root.loginDialog = true
    },
    openDrawerMenu () {
      this.drawerMenuStatus = true
    },
    handleCloseDrawer () {
      this.drawerMenuStatus = false
    },
    handleSelect (key, keyPath) {
      console.log(key, keyPath)
    }
  }
}

</script>
