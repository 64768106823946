<template>
  <div class="basket">
    <div class="page-title-area p-t-150 p-b-100">
      <div class="container">
        <el-row class="row">
          <el-col :span="22" :offset="3">
            <div class="page-titel-detalis  ">
              <div class="section-title">
                <h2>Төлбөр</h2>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="wishlist-area shopping_cart shop-list p-b-40">
      <div class="container">
        <el-row type="flex" justify="center">
          <el-col :xs="20" :sm="20" :md="12" :lg="12" :xl="8">
            <div class="checkout-area">
              <div class="container">
                <div>
                  <div>
                    <!-- <div class="checkout">
                      <p>Returning customer ?<a href="checkout_page.html"> Click here to login</a></p>
                      <p>Have a Coupon ? <a href="my_wishlist.html">Click here to enter your code</a></p>
                    </div> -->
                    <div class="bill-details p-t-0 text-center">
                      <h6>Төлбөрийн хэлбэр</h6>
                      <el-radio-group v-model="pay_type" size="mini">
                        <el-radio label="1">
                          Шилжүүлэг
                        </el-radio>
                        <el-radio label="3">
                          Qpay
                        </el-radio>
                      </el-radio-group>
                    </div>
                    <div class="product-order p-l-50 p-r-50 p-t-30 p-b-55 m-t-50 m-b-50 payment_description">
                      <div v-show="pay_type == '1'">
                        Харилцагч банк: "Хаан банк"<br>
                        Данш эзэмшигч нэр: "Б. Мөнхнасан"<br>
                        Дансны дугаар: <b>5305979107</b><br>
                        Мөнгөн дүн: <b>{{ lastOrderGetter.total_amount | formatCurrency }}</b><br>
                        Гүйлгээний утга: <b>{{ lastOrderGetter.id }}</b> <br>/ Тухайн өдрийн 18:00-с хойш төлбөр хийгдсэн тохиолдол дараа өдрийн захиалганд шилжихийг анхаарна уу.
                      </div>
                      <div v-show="pay_type == '3'" class="text-center">
                        <el-image
                          style="width: 250px; height: 250px"
                          :src="qpayImg"
                          fit="fill"
                        /><br>
                        Мөнгөн дүн: <b>{{ lastOrderGetter.total_amount | formatCurrency}}</b><br>
                        Гүйлгээний утга: <b>{{ lastOrderGetter.id }}</b>
                      </div>
                      <div class="payment-method mt-55">
                        <button class="p-btn m-t-45" @click="goToHome()">
                          Амжилттай
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import Qpay from '../../assets/qpay.png'
import {
  mapGetters
} from 'vuex'
export default {
  name: 'OrderAuthComponent',
  data () {
    return {
      valueNumber: 1,
      checked: false,
      pay_type: '1',
      qpayImg: Qpay
    }
  },
  computed: {
    ...mapGetters([
      'lastOrderGetter'
    ])
  },
  methods: {
    goToHome () {
      this.$router.push({
        path: '/'
      })
    },
    totalAmountCalc () {
      let sum = 0
      let total = 0
      this.lastOrderGetter.products.forEach(element => {
        sum = element.item_price * element.item_count
        total = sum + total
      })
      return total
    }
  }

}
</script>
