import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../pages/index.vue'
import Basket from '../pages/basket.vue'
import CheckOut from '../pages/checkout.vue'
import Payment from '../pages/order_auth.vue'
import LoginPage from '../pages/loginPage.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/basket',
    name: 'basket',
    component: Basket
  },
  {
    path: '/checkout',
    name: 'checkout',
    component: CheckOut
  },
  {
    path: '/payment_auth',
    name: 'Payment',
    component: Payment
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginPage
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
