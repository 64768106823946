<template>
  <div>
    <footer class="footer-area">
      <div class="container">
        <div class="footer-menu p-t-120 p-b-100">
          <el-row :gutter="20" type="flex" justify="center">
            <el-col :span="8">
              <div class="footer-widget mb-30 mb-lg-0 pt-15">
                <div class="logo pb-30">
                  <a href="/"><img src="../../assets/drysheep.png" height="100px" alt="img"></a>
                </div>
                <div class="footer-info">
                  <ul>
                    <li>Email: <span class="ml-5">sales@drysheep.mn</span></li>
                    <li>Утас: <span class="ml-5">
                      <a href="tel:+(976)-72-22-66-66">72226666</a>
                      </span></li>
                  </ul>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
export default {
  name: 'FooterComponent'
}

</script>
