<template>
  <div>
    <HeaderComponent />
    <OrderAuthComponent />
    <FooterComponent />
  </div>
</template>

<script>
import HeaderComponent from '../components/header/index.vue'
import FooterComponent from '../components/footer/index.vue'
import OrderAuthComponent from '../components/order/orderAuth.vue'
export default {
  name: 'CheckoutPage',
  components: { HeaderComponent, FooterComponent, OrderAuthComponent }
}
</script>
