<template>
  <body oncontextmenu="return true">
    <div v-if="show">
      <HeaderComponent />
      <SliderComponent />
      <ProductComponent />
      <FooterComponent />
      <FullScreenLoader/>
    </div>
    <div v-else>
      Тун удахгүй
    </div>
    <LoginDialog v-if="this.$root.loginDialog"/>
  </body>
</template>

<script>
import HeaderComponent from '../components/header/index.vue'
import SliderComponent from '../components/slider/index.vue'
import ProductComponent from '../components/product/index.vue'
import FooterComponent from '../components/footer/index.vue'
import LoginDialog from '../components/login/index.vue'
import FullScreenLoader from '../components/loader/loader-item.vue'

export default {
  name: 'IndexPage',
  components: { HeaderComponent, SliderComponent, ProductComponent, FooterComponent, LoginDialog, FullScreenLoader },
  data () {
    return {
      show: true
    }
  }
}
</script>
